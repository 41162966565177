// 引入路由组件

//路由懒加载

export default [
  // 重定向，在项目跑起来的时候，访问/，直接定向到首页
  {
    path: "",
    hidden: true,
    component: () => import("@/layout"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("@/page/home.vue"),
        name: "/home",
        meta: {
          title: "首页",
          requiresAuth: true, //登录权限
        },
      },
    ],
  },
  {
    path: "/message",
    hidden: true,
    component: () => import("@/layout"),
    redirect: "/message",
    children: [
      {
        path: "/message",
        component: () => import("@/page/message"),
        name: "/message",
        meta: {
          title: "用户管理",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/message",
        },
      },
    ],
  },
  {
    path: "/roleManagement",
    hidden: true,
    component: () => import("@/layout"),
    redirect: "/roleManagement",
    children: [
      {
        path: "/roleManagement",
        component: () => import("@/page/roleManagement"),
        name: "/roleManagement",
        meta: {
          title: "角色管理",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/roleManagement",
        },
      },
    ],
  },
  {
    path: "/merchantManagement",
    hidden: true,
    component: () => import("@/layout"),
    meta: {
      title: "商户管理",
    },
    // redirect: '/systemTool/codeGeneration',
    children: [
      {
        path: "merchantInfo",
        component: () => import("@/page/merchantManagement/merchantInfo"),
        name: "/merchantManagement/merchantInfo",
        meta: {
          title: "商户信息",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/merchantManagement/merchantInfo",
        },
      },
      {
        path: "merchantAppInfo",
        component: () => import("@/page/merchantManagement/merchantAppInfo"),
        name: "/merchantManagement/merchantAppInfo",
        meta: {
          title: "应用管理",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/merchantManagement/merchantAppInfo",
        },
      },
    ],
  },
  {
    path: "/payManagement",
    hidden: true,
    component: () => import("@/layout"),
    meta: {
      title: "支付配置",
    },
    // redirect: '/systemTool/codeGeneration',
    children: [
      {
        path: "payInterfaceInfo",
        component: () => import("@/page/payManagement/payInterfaceInfo"),
        name: "/payManagement/payInterfaceInfo",
        meta: {
          title: "支付接口",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/payManagement/payInterfaceInfo",
        },
      },
      {
        path: "payWayInfo",
        component: () => import("@/page/payManagement/payWayInfo"),
        name: "/payManagement/payWayInfo",
        meta: {
          title: "支付方式",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/payManagement/payWayInfo",
        },
      },
    ],
  },
  {
    path: "/orderManagement",
    hidden: true,
    component: () => import("@/layout"),
    meta: {
      title: "订单管理",
    },
    // redirect: '/systemTool/codeGeneration',
    children: [
      {
        path: "payOrderInfo",
        component: () => import("@/page/orderManagement/payOrderInfo"),
        name: "/orderManagement/payOrderInfo",
        meta: {
          title: "支付订单",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/orderManagement/payOrderInfo",
        },
      },
      {
        path: "refundOrderInfo",
        component: () => import("@/page/orderManagement/refundOrderInfo"),
        name: "/orderManagement/refundOrderInfo",
        meta: {
          title: "退款订单",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/orderManagement/refundOrderInfo",
        },
      },
      {
        path: "merchantNoticeInfo",
        component: () => import("@/page/orderManagement/merchantNoticeInfo"),
        name: "/orderManagement/merchantNoticeInfo",
        meta: {
          title: "商户通知",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/orderManagement/merchantNoticeInfo",
        },
      },
    ],
  },

  {
    path: "/payTest",
    hidden: true,
    component: () => import("@/layout"),
    redirect: "/payTest",
    children: [
      {
        path: "/payTest",
        component: () => import("@/page/payTest"),
        name: "/payTest",
        meta: {
          title: "支付测试",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/payTest",
        },
      },
    ],
  },

  {
    path: "/staticCodeManager",
    hidden: true,
    component: () => import("@/layout"),
    redirect: "/staticCodeManager",
    children: [
      {
        path: "/staticCodeManager",
        component: () => import("@/page/staticCodeManager"),
        name: "/staticCodeManager",
        meta: {
          title: "静态码管理",
          requiresAuth: true, //登录权限
          permissions: ["admin"],
          activeMenu: "/staticCodeManager",
        },
      },
    ],
  },
  {
    path: "/document",
    hidden: true,
    component: () => import("@/layout"),
    meta: {
      title: "开发文档",
    },
    children: [
      {
        path: "accessGuide",
        component: () => import("@/page/document/accessGuide"),
        name: "/document/accessGuide",
        meta: {
          title: "接入指引",
          requiresAuth: true, //登录权限
          activeMenu: "/document/accessGuide",
        },
      },
      {
        path: "callingRule",
        component: () => import("@/page/document/callingRule"),
        name: "/document/callingRule",
        meta: {
          title: "调用规则",
          requiresAuth: true, //登录权限
          activeMenu: "/document/callingRule",
        },
      },
      {
        path: "sampleCode",
        component: () => import("@/page/document/sampleCode"),
        name: "/document/sampleCode",
        meta: {
          title: "示例代码",
          requiresAuth: true, //登录权限
          activeMenu: "/document/sampleCode",
        },
      },
    ],
  },

  {
    path: "/pay",
    component: () => import("@/page/pay/index.vue"),
    name: "pay",
    meta: {
      title: "pay",
      hidden: false,
      icon: "Promotion",
    },
  },
  {
    path: "/aliPay",
    component: () => import("@/page/pay/aliPay.vue"),
    name: "aliPay",
    meta: {
      title: "aliPay",
      hidden: false,
      icon: "Promotion",
    },
  },
  {
    path: "/wechatPay",
    component: () => import("@/page/pay/wechatPay.vue"),
    name: "wechatPay",
    meta: {
      title: "wechatPay",
      hidden: false,
      icon: "Promotion",
    },
  },
  {
    path: "/login",
    component: () => import("@/page/login/login.vue"),
    name: "login",
    meta: {
      title: "登录",
      hidden: false,
      icon: "Promotion",
    },
  },
  {
    path: "/giteeLogin",
    component: () => import("@/page/login/giteeLogin.vue"),
    name: "giteeLogin",
    meta: {
      title: "gitee授权登录",
      hidden: false,
      icon: "Promotion",
    },
  },
  {
    path: "/staticQrCodePay",
    component: () => import("@/page/staticQrCodePay/index.vue"),
    name: "staticQrCodePay",
    // meta: {
    //   title: "登录",
    //   hidden: false,
    //   icon: "Promotion",
    // },
  },
];

// 配置路由的地方
import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import routes from './routes';
import { getToken } from '@/utils/auth'   //获取token
// import route from './route'
// 使用插件
Vue.use(VueRouter);
Vue.use(ElementUI);

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

//重写push|replace
//第一个参数：告诉原来的push方法，往哪里跳转，后面是成功和失败
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject);
    } else {
        originReplace.call(this, location, () => {
        }, () => {
        });
    }
}

// 配置路由
const router= new VueRouter({
    // 配置路由
    routes,

    //滚动行为
    scrollBehavior(to,from ,savedPosition){
        //返回的y=0，代表垂直方向y轴在最上方
        return {y:0};
    }
})


router.beforeEach((to, from, next) => {
    const userToken = getToken();
    const userRole = localStorage.getItem('userRole');
    // 检查路由元信息
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 用户需要身份验证，你可以在这里检查用户是否已登录
        if (userToken) {
            if (to.matched.some(record => record.meta.requiresAdmin)) {
                // 用户需要管理员权限，你可以在这里检查用户的权限
                // console.log(userRole.includes("admin"))
                // console.log(userRole)
                if (userRole.includes("admin")) {
                    next() // 放行，允许管理员访问路由
                } else {
                    // 用户不是管理员，可以处理权限不足的情况
                    // 例如，重定向到一个错误页面
                    ElementUI.Message({
                        message: '您的权限不足',
                        type: 'error'
                    });
                }
            } else {
                next()
            }
        } else {
            next('/login')
        }
    } else {
        next() // 允许访问没有特殊要求的路由
    }
})

export default router
